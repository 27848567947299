import DashboardCategoriesSection from "./Sections/DashboardCategoriesSection";
import DashboardWorkOrdersSection from "./Sections/DashboardWorkOrdersSection";
import Header from "../../Shared/Header";
import NavigationBar from "../../Shared/NavigationBar";

export default function DashboardPage({ currentDate }) {
  return (
    <div className="page-container">
      <NavigationBar menuSelected="Dashboard" />
      <Header title="Dashboard" />
      <DashboardCategoriesSection currentDate={currentDate} />
      <DashboardWorkOrdersSection />
    </div>
  );
}
