import { useState } from "react";

import PrimaryButton from "../../../Shared/PrimaryButton";
import SearchBar from "../../../Shared/SearchBar";
import InventoryTable from "../../../Shared/InventoryTable";
import Pagination from "../../../Shared/Pagination";
import AllocateItemModal from "../../../Shared/Modals/AllocateItemModal";
import AddInventoryModal from "../../../Shared/Modals/AddInventoryModal";

export default function InventorySection({ currentDate }) {
  const rooms = {
    objects: [
      {
        "room number": "335",
        category: "Television",
        name: "Keshava",
        "serial number": "TV-7635",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "27/05/22, 3:30 PM",
        status: "In-Bag",
      },
      {
        "room number": "201",
        category: "Laptop",
        name: "John Doe",
        "serial number": "LP-4521",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "-",
        status: "Allocated",
      },
      {
        "room number": "512",
        category: "Chair",
        name: "Jane Smith",
        "serial number": "CH-7890",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "10/08/22, 2:00 PM",
        status: "VIP",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "-",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
      {
        "room number": "410",
        category: "Refrigerator",
        name: "Michael Johnson",
        "serial number": "RF-1023",
        "created date": "27/05/22, 3:30 PM",
        "allocated date": "03/09/22, 1:00 PM",
        status: "Allocated",
      },
    ],
  };

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = rooms.objects.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentRooms = rooms?.objects?.slice(startIndex, endIndex);

  // console.log("Current rooms", currentRooms);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="ml-8">
      {showModal && <AddInventoryModal onClose={toggleModal} />}
      <p className="text-textGrey text-sm text-start">{currentDate}</p>
      <div className="flex justify-between mt-6">
        <div className="flex">
          <SearchBar placeholder="Search" />
          <PrimaryButton filter btnText="Filter" />
          <button className="ml-6 text-xs text-darkBlue font-semibold">
            Clear filters
          </button>
        </div>
        <PrimaryButton btnText="Add inventory" handleClick={toggleModal} />
      </div>
      <InventoryTable data={currentRooms} />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
