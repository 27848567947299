import { twMerge } from "tailwind-merge";

export default function PrimaryButton({
  filter,
  btnText,
  handleClick,
  modalButton,
}) {
  const buttonClass = twMerge(
    `flex items-center bg-darkBlue text-white text-[10px] shadow-md rounded pt-[9px] pb-[8px] px-4 hover:border hover:border-borderPrimaryDefault hover:bg-white hover:text-borderPrimaryDefault hover:font-semibold
    ${
      filter
        ? "text-xs before:mr-2 before:content-[url('../src/images/filter-icon-white.svg')] before:hover:content-[url('../src/images/filter-icon-blue.svg')]"
        : ""
    }
    ${
      modalButton ? "w-full justify-center mt-4 h-12 text-base font-medium" : ""
    }`
  );

  return (
    <div>
      <button className={`${buttonClass}`} onClick={handleClick}>
        {btnText}
      </button>
    </div>
  );
}
