import { useState, useEffect } from "react";
import axios from "axios";

import CategoryCard from "../../../Shared/CategoryCard";
import PrimaryButton from "../../../Shared/PrimaryButton";
import AddCategoryModal from "../../../Shared/Modals/AddCategoryModal";

export default function CategoriesSection({ token, user, currentDate }) {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/inventoryType/getInventoryDetailsPerTypeCount`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [token]);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="ml-8 mt-8">
        {showModal && (
          <AddCategoryModal onClose={toggleModal} token={token} user={user} />
        )}
        <div className="flex justify-between mr-8 mb-4">
          <p className="text-start text-sm text-textGrey mb-4">{currentDate}</p>
          <PrimaryButton btnText="Add new Category" handleClick={toggleModal} />
        </div>
        <div className="grid grid-cols-5 gap-4">
          {categories &&
            categories.map((category, index) => (
              <CategoryCard
                key={category.INVENTORY_TYPE_ID}
                categoryText={category.INVENTORY_TYPE}
                number={category.COUNT}
                index={index % 5}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
