import AllocatedSection from "./Sections/AllocatedSection";
import Header from "../../Shared/Header";
import NavigationBar from "../../Shared/NavigationBar";

export default function AllocatedPage({ currentDate }) {
  return (
    <div className="page-container">
      <NavigationBar menuSelected="Allocated" />
      <Header title="Allocated" />
      <AllocatedSection currentDate={currentDate} />
    </div>
  );
}
