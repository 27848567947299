import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { Link } from "react-router-dom";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { useEffect, useState } from "react";
import Header from "./Header";
import Allocated from "../../images/allocated-icon.svg";
import Categories from "../../images/categories-icon.svg";
import Dashboard from "../../images/dashboard-icon.svg";
import Inventory from "../../images/inventory-icon.svg";
import Settings from "../../images/settings-icon.svg";
import WorkOrders from "../../images/work-orders-icon.svg";

export default function NavigationBar({ menuSelected }) {
  return (
    <div>
      {/* <Header className="ml-48" /> */}
      <SideNav defaultExpanded>
        <p className="my-8 text-xl text-white -ml-12"> PMS Inventory </p>
        <SideNav.Nav defaultSelected={menuSelected}>
          <NavItem eventKey="Dashboard">
            <NavIcon>
              <img src={Dashboard} alt="dashboard icon" className="pt-4 ml-5" />
            </NavIcon>
            <NavText>
              <Link to="/dashboard">
                <span className="nav-bar-menu text-xs"> Dashboard </span>
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="Categories">
            <NavIcon>
              <img
                src={Categories}
                alt="categories icon"
                className="pt-4 ml-5"
              />
            </NavIcon>
            <NavText>
              <Link to="/categories">
                <span className="nav-bar-menu text-xs"> Categories </span>
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="Work Orders">
            <NavIcon>
              <img
                src={WorkOrders}
                alt="work orders icon"
                className="pt-4 ml-5"
              />
            </NavIcon>
            <NavText>
              <Link to="/work-orders">
                <span className="nav-bar-menu text-xs"> Work Orders </span>
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="Allocated">
            <NavIcon>
              <img src={Allocated} alt="allocated icon" className="pt-4 ml-5" />
            </NavIcon>
            <NavText>
              <Link to="/allocated">
                <span className="nav-bar-menu text-xs"> Allocated </span>
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="Inventory">
            <NavIcon>
              <img src={Inventory} alt="inventory icon" className="pt-4 ml-5" />
            </NavIcon>
            <NavText>
              <Link to="/inventory">
                <span className="nav-bar-menu text-xs"> Inventory </span>
              </Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
        <div className="fixed bottom-0 left-0">
          <SideNav.Nav>
            <NavItem eventKey="Settings">
              <NavIcon>
                <img src={Settings} alt="settings icon" className="pt-4 ml-5" />
              </NavIcon>
              <NavText>
                <span className="nav-bar-menu text-xs"> Settings </span>
              </NavText>
            </NavItem>
          </SideNav.Nav>
        </div>
      </SideNav>
    </div>
  );
}
