import Allocated from "../../images/dashboard/allocated-icon.svg";
import Categories from "../../images/dashboard/categories-icon.svg";
import Inventory from "../../images/dashboard/inventory-icon.svg";
import Repairing from "../../images/dashboard/repairing-icon.svg";
import Total from "../../images/dashboard/total-items-icon.svg";

export default function CategoryCard({ categoryText, number, index }) {
  // const status = "Inventory";
  return (
    <div className="mt-4 flex flex-col min-w-[204px] py-[15px] px-[20px] rounded-xl shadow-md">
      <div className="flex items-center pb-2">
        {index === 0 && <img src={Total} alt="total icon" className="mr-2" />}
        {index === 1 && (
          <img src={Categories} alt="categories icon" className="mr-2" />
        )}
        {index === 2 && (
          <img src={Allocated} alt="allocated icon" className="mr-2" />
        )}
        {index === 3 && (
          <img src={Inventory} alt="inventory icon" className="mr-2" />
        )}
        {index === 4 && (
          <img src={Repairing} alt="repairing icon" className="mr-2" />
        )}
        <span className="text-xs text-grey">{categoryText}</span>
      </div>
      <div className="flex">
        <div className="text-2xl text-textDark"> {number ? number : 47} </div>
      </div>
    </div>
  );
}
