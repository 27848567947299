import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Suspense } from "react";

import LoadScreen from "./components/Shared/LoadScreen";

import DashboardPage from "./components/Pages/Dashboard/DashboardPage";
import WorkOrdersPage from "./components/Pages/WorkOrders/WorkOrdersPage";
import CategoriesPage from "./components/Pages/Categories/CategoriesPage";
import AllocatedPage from "./components/Pages/Allocated/AllocatedPage";
import InventoryPage from "./components/Pages/Inventory/InventoryPage";

import LoginPage from "./components/Pages/LoginPage";

const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));

  const today = new Date();

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${daysOfWeek[today.getDay()]}, ${today.getDate()} ${
    months[today.getMonth()]
  } ${today.getFullYear()}`;

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ProtectedRoute token={token}>
                  <DashboardPage token={token} currentDate={formattedDate} />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/work-orders"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ProtectedRoute token={token}>
                  <WorkOrdersPage token={token} currentDate={formattedDate} />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/categories"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ProtectedRoute token={token}>
                  <CategoriesPage
                    token={token}
                    user={user}
                    currentDate={formattedDate}
                  />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/allocated"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ProtectedRoute token={token}>
                  <AllocatedPage token={token} currentDate={formattedDate} />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/inventory"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ProtectedRoute token={token}>
                  <InventoryPage token={token} currentDate={formattedDate} />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
