export default function WorkOrdersCard({ room }) {
  const { room_number, room_type, room_status, tower, assignee } = room || {};
  // const status = "Checked Out";
  // console.log("Room:", room);
  return (
    <div className="grid grid-cols-2 gap-4 max-w-[254px] bg-white p-[14px] rounded-lg shadow-lg">
      <div className="font-semibold text-sm text-left"> #{room_number} </div>
      <div
        className={`
        rounded-full ${
          room_status === "Under Maintenance"
            ? "text-orange bg-lightOrange"
            : room_status === "Occupied"
            ? "text-green bg-lightGreen"
            : "text-purple bg-lightPurple"
        } text-[9px] pt-1 text-center h-[20px]`}
      >
        {room_status}
      </div>
      <div className="text-xs text-left">
        <span className="text-darkGrey">Room: </span>
        <span className="font-semibold"> {room_type} </span>
      </div>
      <div className="text-[10px] text-right">Assignee: {assignee}</div>
      <div className="text-[10px] text-darkGrey text-left">
        <span>Tower: </span>
        <span className="font-bold">{tower}</span>
      </div>
      <div className="text-[10px] text-purple underline cursor-pointer text-right">
        View details
      </div>
    </div>
  );
}
