import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
// import ReactTooltip from "react-tooltip";
import MagnifyingGlass from "../../images/magnifying-glass.svg";
import CloseIcon from "../../images/close-button.svg";

export default function SearchBar({
  id,
  placeholder,
  color,
  backgroundColor,
  className,
  fullWidth,
  wrapperClass,
  setSearchField,
  searchField,
  //   tooltipMessage,
  handlePage,
}) {
  const [searchEntered, setSearchEntered] = useState("");
  //   const [tooltip, setTooltip] = useState(false);

  const handleChange = (e) => {
    setSearchField(e.target.value.toLowerCase());
    setSearchEntered(e.target.value);
    handlePage && handlePage();
  };

  const clearInput = () => {
    setSearchField("");
    setSearchEntered("");
    handlePage && handlePage();
  };

  return (
    <div
      className={twMerge(`SearchBtnWrapper max-w-[272px] flex border border-textGrey rounded-[5px] h-[2.75rem] md:w-[272px] mr-8
      ${wrapperClass || "w-[40%]"}`)}
    >
      <input
        type="search"
        id={id}
        placeholder={placeholder}
        value={searchEntered}
        className={twMerge(
          `SearchBtn border-0 rounded-lg p-4 pl-[20px] w-full text-sm focus:ring-0 placeholder:font-normal placeholder:text-[#A8A8A8] placeholder-shown:truncate text-${color} bg-${backgroundColor} 
          ${fullWidth && "w-full"} ${className}`
        )}
        onChange={handleChange}
        data-tip
        data-for="searchHint"
        // onMouseEnter={() => setTooltip(true)}
        // onMouseLeave={() => {
        //   setTooltip(false);
        // }}
      />
      {/* {tooltip && tooltipMessage !== undefined && (
        <ReactTooltip id="searchHint" type="dark" place="bottom" effect="solid">
          <span>{tooltipMessage}</span>
        </ReactTooltip>
      )} */}
      {searchField && searchEntered.length > 0 ? (
        <img
          src={CloseIcon}
          alt="search-icon"
          className="grid place-items-center p-3.5 pr-[20px] cursor-pointer"
          onClick={clearInput}
        />
      ) : (
        <img
          src={MagnifyingGlass}
          alt="search-icon"
          className="grid place-items-center p-3.5 pr-[20px]"
        />
      )}
    </div>
  );
}
