import CategoryCard from "../../../Shared/CategoryCard";

export default function DashboardCategoriesSection({ currentDate }) {
  return (
    <div className="ml-8">
      <p className="text-textGrey text-sm text-start">{currentDate}</p>
      <div className="flex gap-9">
        <CategoryCard categoryText="Total Items" index={0} />
        <CategoryCard categoryText="Categories" index={1} />
        <CategoryCard categoryText="Allocated" index={2} />
        <CategoryCard categoryText="Inventory Stock" index={3} />
        <CategoryCard categoryText="Repairing" index={4} />
      </div>
    </div>
  );
}
