import { useState } from "react";

import Cross from "../../../images/cross-icon.svg";
import PrimaryButton from "../PrimaryButton";

const AllocateItemModal = ({ onClose }) => {
  const [category, setCategory] = useState("");
  const [room, setRoom] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [itemId, setItemId] = useState("");

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-10 outline-none backdrop-brightness-[0.2] focus:outline-none">
      <div className="relative my-6 mx-auto h-[90%] w-[30%] max-w-[750px] rounded-2xl bg-white md:min-w-[350px] md:h-[70%] md:max-h-[800px]">
        <div className="relative items-center flex h-full w-full flex-col rounded-2xl border-0 bg-white shadow-lg outline-none focus:outline-none">
          <div className="flex items-start px-4 rounded-t w-full">
            <h3 className="text-xl font-bold text-purple top-12 relative">
              Allocate Item
            </h3>
            <button
              className="absolute border-0 bg-transparent text-black top-8 right-4"
              onClick={onClose}
            >
              <img src={Cross} alt="" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-4 md:flex-row w-full px-4 mt-20">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-col px-4">
                <div className="flex font-semibold py-2">
                  <span className="text-lightGrey text-xs">ALLOCATING BY</span>
                  <span className="text-offBlack ml-20 text-sm"> Keshava </span>
                </div>
                <div className="flex font-semibold py-2">
                  <span className="text-lightGrey text-xs"> BUILDING </span>
                  <span className="text-offBlack ml-28 text-sm">
                    Bhadra Nivas
                  </span>
                </div>
              </div>
              <div className="flex flex-row gap-x-8 px-1 justify-between text-left my-2">
                <div className="flex flex-col justify-items-start w-full">
                  <label
                    htmlFor="category"
                    className="text-xs font-semibold text-offBlack mb-1"
                  >
                    Category
                  </label>
                  <select
                    id="category"
                    className="border border-lightGrey rounded px-2 py-1 text-xs h-10 text-lightGrey"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                  </select>
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="category"
                    className="text-xs font-semibold text-offBlack mb-1"
                  >
                    Room No
                  </label>
                  <select
                    id="category"
                    className="border border-red-400 rounded px-2 py-1 text-xs h-10 border-lightGrey text-lightGrey"
                    value={category}
                    onChange={(e) => setRoom(e.target.value)}
                  >
                    <option value="">Select Room</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col my-2">
                <label
                  htmlFor="timePeriod"
                  className="text-left text-xs font-semibold text-offBlack mb-1"
                >
                  Time Period:
                </label>
                <input
                  type="text"
                  id="timePeriod"
                  className="border rounded px-2 py-1 text-xs h-10 border-lightGrey text-lightGrey"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  placeholder="Select Date and Time for item allocation"
                />
              </div>
              <div className="flex flex-col my-2">
                <label
                  htmlFor="itemId"
                  className="text-xs font-semibold text-offBlack text-left mb-1"
                >
                  Item Id - (Serial number):
                </label>
                <select
                  id="itemId"
                  className="border rounded px-2 py-1 text-xs h-10 border-lightGrey text-lightGrey"
                  value={itemId}
                  onChange={(e) => setItemId(e.target.value)}
                >
                  <option value="">Select Item Serial No</option>
                </select>
              </div>
              <PrimaryButton btnText="Allocate" modalButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllocateItemModal;
