import { useEffect } from "react";
import WorkOrdersCard from "../../../Shared/WorkOrdersCard";
import PrimaryButton from "../../../Shared/PrimaryButton";

export default function WorkOrdersSection({ currentDate }) {
  // useEffect(() => {
  const data = {
    rooms: [
      {
        room_number: "101",
        room_type: "F2-212",
        room_status: "Occupied",
        tower: "Bhadra Nivas1",
        assignee: "John Doe",
      },
      {
        room_number: "202",
        room_type: "F3-314",
        room_status: "Checked Out",
        tower: "Bhadra Nivas2",
        assignee: "Kumara",
      },
      {
        room_number: "305",
        room_type: "F1-109",
        room_status: "Under Maintenance",
        tower: "Bhadra Nivas3",
        assignee: "Maintenance Staff",
      },
      {
        room_number: "410",
        room_type: "F2-203",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "510",
        room_type: "F5-503",
        room_status: "Under Maintenance",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "106",
        room_type: "F1-703",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "107",
        room_type: "F2-203",
        room_status: "Occupied",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "903",
        room_type: "F5-503",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "289",
        room_type: "F5-503",
        room_status: "Occupied",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "357",
        room_type: "F5-503",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "498",
        room_type: "F5-503",
        room_status: "Occupied",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "357",
        room_type: "F5-503",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "498",
        room_type: "F5-503",
        room_status: "Occupied",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "357",
        room_type: "F5-503",
        room_status: "Checked Out",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      {
        room_number: "498",
        room_type: "F5-503",
        room_status: "Occupied",
        tower: "Bhadra Nivas",
        assignee: "Kumara",
      },
      // {
      //   room_number: "357",
      //   room_type: "F5-503",
      //   room_status: "Checked Out",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "498",
      //   room_type: "F5-503",
      //   room_status: "Occupied",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "357",
      //   room_type: "F5-503",
      //   room_status: "Checked Out",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "498",
      //   room_type: "F5-503",
      //   room_status: "Occupied",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "357",
      //   room_type: "F5-503",
      //   room_status: "Checked Out",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "498",
      //   room_type: "F5-503",
      //   room_status: "Occupied",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "357",
      //   room_type: "F5-503",
      //   room_status: "Checked Out",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "498",
      //   room_type: "F5-503",
      //   room_status: "Occupied",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "357",
      //   room_type: "F5-503",
      //   room_status: "Checked Out",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
      // {
      //   room_number: "498",
      //   room_type: "F5-503",
      //   room_status: "Occupied",
      //   tower: "Bhadra Nivas",
      //   assignee: "Kumara",
      // },
    ],
  };
  // }, []);
  return (
    <div>
      <div className="ml-8 mt-8">
        <div className="flex justify-between mr-8 mb-4">
          <p className="text-start text-sm text-textGrey mb-4">{currentDate}</p>
          <PrimaryButton btnText="Create work order" />
        </div>
        <div className="grid grid-cols-4 gap-4">
          {data.rooms.map((room) => (
            <WorkOrdersCard room={room} key={room.room_number} />
          ))}
        </div>
      </div>
      {/* <div className="flex items-center justify-center mt-8">
        <PrimaryButton btnText="View All" />
      </div> */}
    </div>
  );
}
