import Burger from "../../images/burger-icon.svg";
import User from "../../images/user-icon.svg";
import DownArrow from "../../images/down-arrow.svg";
import { useState, useEffect, useRef } from "react";

export default function Header({ title }) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const closeDropDown = (e) => {
      if (
        e.target.id !== "paragraph" &&
        e.target.id !== "arrow" &&
        showMenu === true
      ) {
        setShowMenu(false);
      }
    };
    document.body.addEventListener("click", closeDropDown);
    return () => document.body.removeEventListener("click", closeDropDown);
  }, [showMenu]);

  return (
    <div className="flex justify-between mx-8 my-8">
      <div className="flex">
        <img src={Burger} alt="burger icon" />
        <span className="text-textDark text-2xl font-semibold ml-4 mt-2 whitespace-nowrap">
          {title}
        </span>
      </div>
      <div className="flex items-center" onClick={() => setShowMenu(!showMenu)}>
        <img src={User} alt="user icon" />
        <div className="flex flex-col">
          <p
            id="paragraph"
            className="text-sm mr-3 font-semibold min-w-fit flex md:text-sm md:mt-[2px] cursor-pointer text-textDark"
            onClick={() => setShowMenu(!showMenu)}
            ref={menuRef}
          >
            Keshava
          </p>
          <p
            className="text-[8px] text-grey"
            onClick={() => setShowMenu(!showMenu)}
          >
            Inventory Manager
          </p>
        </div>
        <img
          src={DownArrow}
          alt="down arrow icon"
          className="ml-4 hover:cursor-pointer"
          onClick={() => setShowMenu(!showMenu)}
          ref={menuRef}
          id="arrow"
        />
      </div>
      {showMenu && (
        <div className="text-sm font-semibold text-textDark absolute overflow-hidden top-20 z-10 flex min-w-[170px] flex-col rounded-md right-4 cursor-pointer">
          <div className="border-transparent border-l-4 hover:bg-lightOrange hover:border-darkGreen px-1 py-2">
            Select1
          </div>
          <div className="border-transparent border-l-4 hover:bg-lightOrange hover:border-darkGreen px-1 py-2">
            Select2
          </div>
          <div className="border-transparent border-l-4 hover:bg-lightOrange hover:border-darkGreen px-1 py-2">
            Logout
          </div>
        </div>
      )}
    </div>
  );
}
