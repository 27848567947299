import { useState } from "react";
import axios from "axios";

import Cross from "../../../images/cross-icon.svg";
import PrimaryButton from "../PrimaryButton";

const AddCategoryModal = ({ onClose, token, user }) => {
  const [description, setDescription] = useState("");
  const [inventoryType, setInventoryType] = useState("");

  const addCategory = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/inventoryType`,
        { inventoryType: inventoryType, description: description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          onClose();
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error adding category:", error);
      });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-10 outline-none backdrop-brightness-[0.2] focus:outline-none">
      <div className="relative my-6 mx-auto h-[90%] w-[30%] max-w-[750px] rounded-2xl bg-white md:min-w-[350px] md:h-[63%] md:max-h-[800px]">
        <div className="relative items-center flex h-full w-full flex-col rounded-2xl border-0 bg-white shadow-lg outline-none focus:outline-none">
          <div className="flex items-start px-4 rounded-t w-full">
            <h3 className="text-xl font-bold text-purple top-12 relative">
              Add Category
            </h3>
            <button
              className="absolute border-0 bg-transparent text-black top-8 right-4"
              onClick={onClose}
            >
              <img src={Cross} alt="" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-4 md:flex-row w-full px-4 mt-20">
            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-col px-4">
                <div className="flex font-semibold py-2">
                  <span className="text-lightGrey text-xs">ADDING BY</span>
                  <span className="text-offBlack ml-[85px] text-sm">
                    {user.username}
                  </span>
                </div>
                <div className="flex font-semibold py-2">
                  <span className="text-lightGrey text-xs"> BUILDING </span>
                  <span className="text-offBlack ml-[94px] text-sm">
                    Bhadra Nivas
                  </span>
                </div>
              </div>

              <div className="flex flex-col my-2">
                <label
                  htmlFor="inventoryType"
                  className="text-left text-xs font-semibold text-offBlack mb-1"
                >
                  Inventory Type
                </label>
                <input
                  type="text"
                  id="inventoryType"
                  className="border rounded px-2 py-1 text-xs h-10 border-lightGrey text-lightGrey"
                  value={inventoryType}
                  onChange={(e) => setInventoryType(e.target.value)}
                  placeholder="Enter inventory type"
                />
              </div>
              <div className="flex flex-col my-2">
                <label
                  htmlFor="description"
                  className="text-left text-xs font-semibold text-offBlack mb-1"
                >
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  className="border rounded px-2 py-1 text-xs h-10 border-lightGrey text-lightGrey"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description"
                />
              </div>
              <PrimaryButton
                btnText="Add Category"
                modalButton
                handleClick={() => addCategory()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryModal;
