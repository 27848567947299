import WorkOrdersSection from "../WorkOrders/Sections/WorkOrdersSection";
import Header from "../../Shared/Header";
import NavigationBar from "../../Shared/NavigationBar";

export default function WorkOrdersPage({ currentDate }) {
  return (
    <div className="page-container">
      <NavigationBar menuSelected="Work Orders" />
      <Header title="Work Orders" />
      <WorkOrdersSection currentDate={currentDate} />
    </div>
  );
}
