import LeftArrow from "../../images/left-arrow.svg";
import RightArrow from "../../images/right-arrow.svg";

export default function Pagination({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  onPageChange,
}) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  return (
    <div className="flex items-center justify-end mt-4 text-textGrey text-sm mb-4">
      <span className="mr-4">
        Showing {Math.min(totalItems, (currentPage - 1) * itemsPerPage + 1)} -{" "}
        {Math.min(totalItems, currentPage * itemsPerPage)} of {totalItems}
      </span>
      {totalItems > 10 ? (
        <div className="flex justify-end">
          <button
            className="px-2 py-1 mr-1 rounded text-sm"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <img src={LeftArrow} alt="" />
          </button>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              className={`px-2 py-1 mx-1 rounded text-sm ${
                pageNumber === currentPage
                  ? "underline underline-offset-4 decoration-2 decoration-textGrey"
                  : ""
              }`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
          <button
            className="px-2 py-1 ml-1 rounded text-sm bg-gray-300"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img src={RightArrow} alt="" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
