import React from "react";
import KebabMenu from "../../images/kebab-menu.svg";

export default function InventoryTable({ data }) {
  return (
    <div className="container mt-8">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-transparent text-base text-left font-medium text-sealBrown h-[50px]">
            <th className="pl-8 pr-4 py-2">Sl. No</th>
            <th className="px-4 py-2">Category</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Serial No</th>
            <th className="px-4 py-2">Created Date</th>
            <th className="px-4 py-2">Allocated Date</th>
            <th className="pr-8 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((room, index) => (
            <React.Fragment key={index}>
              <tr className="bg-cloudWhite text-textGrey text-sm text-left h-[50px]">
                <td className="pl-8 pr-4 py-2">{room["room number"]}</td>
                <td className="px-4 py-2">{room.category}</td>
                <td className="px-4 py-2">{room.name}</td>
                <td className="pl-4 pr-8 py-2">{room["serial number"]}</td>
                <td className="pl-4 pr-0 py-2">{room["created date"]}</td>
                <td className="pl-4 pr-0 py-2">{room["allocated date"]}</td>
                <td className="pr-8 py-2 flex justify-between">
                  <span>{room.status}</span>
                  <img src={KebabMenu} alt="kebab menu" />
                </td>
              </tr>
              <tr className="h-2 bg-transparent"></tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
