import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Shared/PrimaryButton";
import PasswordShow from "../../images/password-view-icon.svg";
import PasswordHide from "../../images/password-hide-icon.svg";

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginError, setLoginError] = useState(false);

  let navigate = useNavigate();

  const loginUser = async () => {
    if (username && password) {
      const data = {
        username: username,
        password: password,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/signin`,
        data
      );

      if (response.status === 200 && response.data.accessToken) {
        sessionStorage.setItem("token", response.data.accessToken);
        const userJsonString = JSON.stringify(response.data);
        sessionStorage.setItem("user", userJsonString);
        setLoginError(false);
        navigate("/dashboard");
        window.location.reload();
      } else {
        setLoginError(true);
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-screen h-screen bg-cloudWhite px-8">
      <div className="max-w-md m-auto pt-24">
        <div className="border-t-4 border-darkBlue overflow-hidden rounded shadow-xl bg-white">
          <h3 className="text-textDark text-2xl font-semibold whitespace-nowrap mt-8 mb-6">
            PMS Inventory
          </h3>
          <div className="px-4 mb-4 text-left">
            <label htmlFor="username" className="text-textDark">
              Username
            </label>
            <input
              type="text"
              placeholder="Enter username"
              className="border border-lightGrey rounded w-full p-3 mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="px-4 mb-4 mt-6 text-left relative">
            <label htmlFor="password" className="text-textDark">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                className="border border-lightGrey rounded w-full p-3 mt-1 pr-10"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute top-5 right-2 focus:outline-none"
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <img key="hide" src={PasswordHide} alt="Hide Password" />
                ) : (
                  <img key="show" src={PasswordShow} alt="Show Password" />
                )}
              </button>
            </div>
            {isLoginError && (
              <p className="text-errorRed text-sm mt-2">
                Invalid username/password.
              </p>
            )}
          </div>
          <div className="px-4 mb-8 mt-8">
            <PrimaryButton
              btnText="Login"
              modalButton
              handleClick={loginUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
