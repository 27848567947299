import CategoriesSection from "./Sections/CategoriesSection";
import Header from "../../Shared/Header";
import NavigationBar from "../../Shared/NavigationBar";

export default function CategoriesPage({ token, user, currentDate }) {
  return (
    <div className="page-container">
      <NavigationBar menuSelected="Categories" />
      <Header title="Categories" />
      <CategoriesSection token={token} user={user} currentDate={currentDate} />
    </div>
  );
}
